<template>
  <div class="page-section link-area" id="mesa-de-regalos" @click="openLink()">
    <div class="page-header d-flex align-items-center justify-content-center">
      <h1 class="page-header__title">Mesa de Regalos</h1>
    </div>
    <div>
      <img class="img-logo-livu d-block" src="img/LOGO LIVU.png" alt="" />
      <img class="img-logo-vyd d-block" src="img/VAL & DAN black.png" alt="" />
      <div class="img-wrapper">
        <img class="img-regalos d-block m-auto" src="img/REGALOS.png" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    openLink() {
      window.open('https://livu.mx/mesa/Valydani', '_blank');
    },
  },
};
</script>

<style lang="scss" scoped>
.img-logo-livu {
  position: relative;
  height: 5vh;
  top: 25px;
}
.img-logo-vyd {
  width: 125px;
  margin: auto;
  position: relative;
  top: 25px;
}
.img-regalos {
  width: 100%;
  height: 70vh;
}
.img-wrapper {
  overflow: hidden;
  img {
    width: 90%;
    height: 70vh;
    overflow: hidden;
    object-fit: cover;
  }
}
</style>
