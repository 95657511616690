import { createStore } from 'vuex';

export default createStore({
  state: {
    portalId: '21313485',
    formGuid: '2b02f850-f894-4af1-a853-d42ff964b0f7',
    btnAsync: undefined,
  },
  mutations: {
    set_btnSpinner(state, target) {
      if (!state.btnAsync) {
        state.btnAsync = target;
        state.btnAsync.disabled = true;
        state.btnAsync.innerHTML = '<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>';
      } else {
        state.btnAsync.disabled = false;
        state.btnAsync.innerHTML = '¡CONFIRMO ASISTENCIA!';
        state.btnAsync = undefined;
      }
    },
  },
  actions: {
    async submitRSVP(context, data) {
      console.log(context, data);
      const response = await fetch(
        `https://api.hsforms.com/submissions/v3/integration/submit/${context.state.portalId}/${context.state.formGuid}`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
        },
      );
      const responseData = await response.json();
      return responseData;
      // if (responseData.status === 'error') {
      //   const error = new Error(responseData.message || 'Error al enviar formulario');
      //   throw error;
      // }
    },
  },
  modules: {},
});
