<template>
  <div id="nav">
    <div class="container p-0">
      <header-nav @toggle-menu="toggleMenu" menu-is-shown="menuIsShown"></header-nav>
      <menu-responsive
        @toggle-menu="toggleMenu"
        menu-is-shown="menuIsShown"
        v-if="menuIsShown"
      ></menu-responsive>
      <val-y-dan></val-y-dan>
      <days-to-go></days-to-go>
      <familia-y-amigos></familia-y-amigos>
      <ubicacion></ubicacion>
      <transporte></transporte>
      <hospedaje></hospedaje>
      <mesa-de-regalos></mesa-de-regalos>
      <rsvp></rsvp>
      <queremos-ver-tus-fotos></queremos-ver-tus-fotos>
    </div>
  </div>
  <router-view />
</template>
<script>
import HeaderNav from '@/components/layout/HeaderNav.vue';
import MenuResponsive from '@/components/layout/MenuResponsive.vue';
import ValYDan from '@/components/ValYDan.vue';
import DaysToGo from '@/components/DaysToGo.vue';
import FamiliaYAmigos from '@/components/FamiliaYAmigos.vue';
import Ubicacion from '@/components/Ubicacion.vue';
import Transporte from '@/components/Transporte.vue';
import Hospedaje from '@/components/Hospedaje.vue';
import MesaDeRegalos from '@/components/MesaDeRegalos.vue';
import RSVP from '@/components/RSVP.vue';
import QueremosVerTusFotos from '@/components/QueremosVerTusFotos.vue';

export default {
  data() {
    return {
      menuIsShown: false,
    };
  },
  components: {
    HeaderNav,
    MenuResponsive,
    ValYDan,
    DaysToGo,
    FamiliaYAmigos,
    Ubicacion,
    Transporte,
    Hospedaje,
    MesaDeRegalos,
    Rsvp: RSVP,
    QueremosVerTusFotos,
  },
  methods: {
    toggleMenu(isShown) {
      this.menuIsShown = isShown;
    },
  },
};
</script>

<style lang="scss">
#app {
  font-family: "Montserrat", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

html,
body {
  background: #000000 !important;
  scroll-behavior: smooth;
}

.container {
  width: 1;
}

.page-section {
  background: #fff;
  height: 100vh;
  max-height: 100vh;
}
.page-header {
  height: 9vh;
  // height: 110px;
  background: #000;
  color: white;

  &__title {
    font-size: 32px;
    // font-size: 64px;
    // line-height: 78px;
    text-transform: uppercase;
    margin: 0;
  }
}

.link-area {
  cursor: pointer;
}

@media (min-width: 992px) {
  .page-header {
    height: 13vh;

    &__title {
      font-size: 64px;
      line-height: 78px;
    }
  }
}
</style>
