<template>
  <div class="page-section" id="val-y-dan">
    <div class="img-wrapper">
      <img class="banner" src="img/val-y-dan-banner.png" alt="" />
    </div>
    <div class="page-header d-flex justify-content-between">
      <div class="details d-flex align-items-center">
        <img class="details__icon" src="img/icons/calendar-icon.png" alt="" />
        <p class="details__text">Sábado 19 de Marzo de 2022</p>
      </div>
      <div class="details d-flex align-items-center">
        <img class="details__icon" src="img/icons/clock-icon.png" alt="" />
        <p class="details__text">16:30 hrs</p>
      </div>
      <div class="details d-flex align-items-center">
        <img class="details__icon" src="img/icons/clothes-icon.png" alt="" />
        <p class="details__text">Formal (Sin Corbata)</p>
      </div>
      <div class="details d-flex align-items-center link-area" @click="openLink">
        <img class="details__icon" src="img/icons/pin-icon.png" alt="" />
        <p class="details__text">Agua Viva</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    openLink() {
      window.open('http://jardinaguaviva.com', '_blank');
    },
  },
};
</script>

<style lang="scss" scoped>
.page-section {
  height: 92vh;
  background: black;
}
.img-wrapper {
  min-width: 100%;
  max-height: 85vh;
  overflow: hidden;
  background: rgb(0, 0, 0);
  img {
    width: 100% !important;
    height: 100% !important;
    min-height: 85vh;
    overflow: hidden;
    object-fit: cover;
  }
}
h1 {
  font-family: "Gruppo";
  position: absolute;
  left: 33%;
}
.page-header {
  height: 7vh;
  padding: 0 15px;
  border: black;

  @media (min-width: 992px) {
    // height: 111px;
    // height: 15vh;
  }
}

.details {
  &__icon {
    height: 22px;
  }

  &__text {
    margin: 0 4px;
    font-size: 10px;
  }

  @media (min-width: 992px) {
    margin-right: 50px;
    &__icon {
      height: 40px;
    }

    &__text {
      margin-left: 23.5px;
      font-size: 20px;
      line-height: 32px;
    }
  }
}
</style>
