<template>
  <div class="page-section" id="ubicacion">
    <div class="page-header d-flex flex-column align-items-center justify-content-center">
      <h1 class="page-header__title">Ubicación</h1>
    </div>
    <div class="location align-items-center justify-content-center align-items-center">
      <div class="d-flex flex-column pic-column">
        <div class="location__name">Jardín Agua Viva</div>
        <a class="location__web-url" href="http://jardinaguaviva.com">www.jardinaguaviva.com</a>
        <div class="img-wrapper">
          <img class="location__photo" src="img/FOTO SALON AGUA VIVA.png" alt="" />
        </div>
      </div>
      <div class="d-flex flex-column align-items-start ms-lg-5 map-column">
        <div class="img-wrapper link-area" @click="openLink()">
          <img class="location__map" src="img/map.jpg" alt="" />
        </div>
        <h3 src="" alt="" class="location__place text-align-left">Xochitepec, Morelos</h3>
        <div class="d-flex flex-column flex-lg-row location__estimated-time">
          <div class="location__distance align-items-start d-flex me-3 align-items-center">
            <h4>Distancia de CDMX:</h4>
            <p>111 km</p>
          </div>
          <div class="d-flex align-items-start align-items-center">
            <h4>Tiempo estimado:</h4>
            <p>1:40 mins</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    openLink() {
      window.open('https://goo.gl/maps/tQaWASUrSm79hJER6', '_blank');
    },
  },
};
</script>

<style lang="scss" scoped>
.page-header {
  height: 8.5vh;
}

.location {
  display: flex;
  flex-direction: column;

  &__name {
    font-size: 30px;
    font-weight: 600;
    color: #767674;
  }
  &__web-url {
    font-size: 24px;
    line-height: 44px;
    text-decoration: none;
    font-weight: 300;
    color: #767674;
  }
  &__photo {
    width: 100%;
    overflow: hidden;
    object-fit: cover;
  }
  &__map {
    width: 100%;
  }
  &__place {
    font-weight: 600;
    font-size: 24px;
    color: #767674;
  }
  &__estimated-time {
    color: #767674;

    h4 {
      font-size: 18px;
      font-weight: 600;
      margin: 0;
    }
    p {
      font-size: 16px;
      margin: 0 5px;
    }
  }

  .pic-column {
    max-height: 40.5vh;
    width: 100%;
    .img-wrapper {
      overflow: hidden;
      background: black;
      width: 100%;
      img {
        width: 100% !important;
        height: 40.5vh !important;
        overflow: hidden;
        object-fit: cover;
      }
    }
  }
  .map-column {
    max-height: 50.5vh;
    width: 100%;
    .img-wrapper {
      overflow: hidden;
      background: black;
      img {
        width: 100% !important;
        overflow: hidden;
        object-fit: cover;
      }
    }
  }

  @media (orientation: landscape) {
    flex-direction: row;
    .pic-column {
      max-height: 90.5vh;
      width: 50%;
    }
    .map-column {
      width: 50%;
      max-height: 90.5vh;
      width: 100%;
    }
    &__web-url {
      font-size: 16px;
    }
  }
  @media (min-height: 576px) and (min-width: 576px) {
  }

  @media (min-height: 576px) and (min-width: 992px) {
    padding: 30px;
    height: 90.5vh;
    &__name {
      font-size: 36px;
      line-height: 44px;
    }
    &__photo {
      width: auto;
      height: auto;
      max-height: 398px;
      max-width: 392px;
    }
    &__map {
      width: auto;
      height: auto;
    }
    &__place {
      font-size: 36px;
      line-height: 44px;
    }
    &__estimated-time {
      h4 {
        font-size: 24px;
      }
      p {
        font-size: 24px;
        font-weight: 300;
      }
    }
    .pic-column {
      max-height: 40.5vh;
      width: 35%;
    }
    .map-column {
      width: 60%;
      max-height: 90.5vh;
      width: 100%;
    }
  }
}
</style>
