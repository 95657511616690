<template>
  <div class="menu-responsive">
    <img
      src="img/icons/icon-close.svg"
      alt=""
      class="icon-close"
      @click="$emit('toggle-menu', false)"
    /><br>
    <img src="img/VAL & DAN.png" alt="" />
    <nav>
      <ul>
        <li><a href="#val-y-dan" @click="$emit('toggle-menu', false)">Home</a></li>
        <li><a href="#val-y-dan" @click="$emit('toggle-menu', false)">Val y Dan</a></li>
        <li><a href="#ubicacion" @click="$emit('toggle-menu', false)">Ubicación</a></li>
        <li><a href="#transporte" @click="$emit('toggle-menu', false)">Transporte</a></li>
        <li><a href="#hospedaje" @click="$emit('toggle-menu', false)">Hospedaje</a></li>
        <li><a href="#rsvp" @click="$emit('toggle-menu', false)">RSVP</a></li>
        <li>
          <a href="#queremos-ver-tus-fotos" @click="$emit('toggle-menu', false)">Tus Fotos</a>
        </li>
      </ul>
    </nav>
  </div>
</template>

<style lang="scss" scoped>
.menu-responsive {
  width: 100vw;
  height: 100vh;
  background: #000000;
  z-index: 1000;
  position: fixed;
  left: 0;
  top: 0;
  overflow: scroll;

  .icon-close {
    position: relative;
    left: 140px;
    margin-top: 72px;
  }

  ul {
    li {
      list-style: none;
      margin-bottom: 38px;

      &:first-of-type {
        margin-top: 60px;
      }

      :hover {
        color: #a5a5a5;
      }
    }
    a {
      text-decoration: none;
      font-weight: normal;
      font-size: 18px;
      line-height: 22px;
      color: white;
      text-transform: uppercase;
    }
  }
}
</style>
