<template>
  <div class="page-section">
    <div class="message">
      <div>
        <h1 class="message__title">Familia y Amigos</h1>
        <hr class="message__divider" />
        <p class="message__text m-0">
          Nos llena de emoción compartir y festejar con ustedes uno de los días más importantes para
          nosotros. <br /><br />
          Puesto que seguimos en pandemia, les pedimos que tomen precauciones unos días antes de la
          boda y, de preferencia, hacerse una prueba para estar todos más tranquilos. <br /><br />
          Para tener una mejor organización del evento, les agradecemos su pronta confirmación de
          asistencia. <br /><br />
          Finalmente, les recordamos que es puente por lo que vale la pena reservar con tiempo.
        </p>
      </div>
    </div>
    <div class="img-wrapper">
      <img src="img/FOTO.png" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.page-section {
  display: flex;
  flex-direction: column;

  @media (orientation: landscape) {
    flex-direction: row;
  }
}

.message {
  background: rgba(144, 144, 141, 0.3);
  padding: 16px;
  height: 50vh;

  &__title {
    font-family: "Gruppo", cursive;
    font-weight: bold;
    font-size: 30px;
  }

  &__divider {
    margin: 24px 0 20px 0;
    border-top: 2px solid black;
  }

  &__text {
    font-weight: 300;
    font-size: 12px;
    text-align: left;
  }

  @media (orientation: landscape) {
    order: 2;
    height: 100vh;
  }

  @media (min-height: 576px) and (min-width: 576px) {
    &__text {
      font-size: 18px;
    }
  }

  @media (min-height: 768px) and (min-width: 992px) {
    padding: 50px;
    height: 100vh;

    &__title {
      font-size: 60px;
    }

    &__divider {
      margin: 50px 0;
    }

    &__text {
      font-size: 24px;
      line-height: 34px;
    }
  }
}
.img-wrapper {
  min-width: 41%;
  min-height: 50vh;
  max-height: 50vh;
  overflow: hidden;
  background: black;
  img {
    background: rgb(0, 0, 0);
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: 50% 0%;
  }
  @media (orientation: landscape) {
    order: 1;
    min-height: 100vh;
    max-height: 100vh;
    object-position: 100% 0%;
  }
}
</style>
