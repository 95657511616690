<template>
  <header>
    <nav class="header-responsive">
      <div class="d-flex justify-content-between align-items-center">
        <h1 class="page-title">VAL Y DAN</h1>
        <img src="img/icons/toggle-icon.svg" alt="" @click="$emit('toggle-menu', true)" />
      </div>
    </nav>
    <nav class="header-desktop">
      <ul>
        <li><a href="#val-y-dan">Home</a></li>
        <li><a href="#val-y-dan">Val y Dan</a></li>
        <li><a href="#ubicacion">Ubicación</a></li>
        <li><a href="#transporte">Transporte</a></li>
        <li><a href="#hospedaje">Hospedaje</a></li>
        <li><a href="#rsvp">RSVP</a></li>
        <li><a href="#queremos-ver-tus-fotos">Tus Fotos</a></li>
      </ul>
    </nav>
  </header>
</template>

<style lang="scss" scoped>
header {
  .header-responsive {
    display: block;
  }
  .header-desktop {
    display: none;
  }

  .page-title {
    color: white;
    margin: 0 !important;
    font-size: 22px;
    font-weight: normal;
  }

  background: black;
  height: 8vh;
  width: 100%;

  nav div {
    height: 8vh;
    padding: 0 15px;
  }

  img {
    height: 22px;
  }

  ul {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 72px;
    margin-top: 0px;
    li {
      list-style: none;
      color: white;

      :hover {
        color: rgb(165, 165, 165);
      }
    }
    a {
      text-decoration: none;
      color: white;
      list-style: none;
      font-size: 18px;
      text-transform: uppercase;
      margin-right: 21px;
    }
  }

  @media (min-width: 992px) {
    height: 8vh;

    .header-responsive {
      display: none;
    }
    .header-desktop {
      display: block;
    }
  }
  @media (min-width: 1200px) {
    ul {
      a {
        margin-right: 41px;
      }
    }
  }
}
</style>
