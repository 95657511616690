<template>
  <div class="page-section" id="rsvp">
    <div class="page-header d-flex align-items-center justify-content-center">
      <h1 class="page-header__title">RSVP</h1>
    </div>
    <div class="d-flex flex-column flex-lg-row">
      <form class="order-lg-2 d-flex flex-column justify-content-center" action="">
        <div class="form-content h-auto">
          <div class="input-wrapper mb-lg-3 align-items-end">
            <div class="d-flex w-100 flex-column me-lg-5">
              <label for="">Nombre y Apellidos</label>
              <input type="text" v-model="fullName" />
            </div>
            <div class="d-flex w-100 flex-column">
              <label for="">Teléfono</label>
              <input type="number" v-model="phone" onkeydown="return event.keyCode !== 69" />
            </div>
          </div>
          <div class="input-wrapper form-row mb-lg-3 align-items-end">
            <div class="d-flex w-100 flex-column me-lg-5">
              <label for="">Email</label>
              <input type="text" v-model="email" />
            </div>
            <div class="d-flex w-100 flex-column">
              <label for="">¿Dónde the hospedarás?</label>
              <input type="text" v-model="accommodation" />
            </div>
          </div>
          <div class="d-flex flex-column form-md-row mb-lg-3">
            <label for="">¿Qué te gustaría tomar?</label>
            <div class="radio-wrapper mt-3">
              <div class="d-flex me-3 mb-lg-2 align-items-center">
                <input
                  class="me-1"
                  id="how-ron"
                  name="drink"
                  type="radio"
                  value="ron"
                  v-model="drink"
                />
                <label class="label-radio" for="how-ron">Ron</label>
              </div>
              <div class="d-flex me-3 mb-lg-2 align-items-center">
                <input
                  class="me-1"
                  id="how-tequila"
                  name="drink"
                  type="radio"
                  value="tequila"
                  v-model="drink"
                />
                <label class="label-radio" for="how-tequila">Tequila</label>
              </div>
              <div class="d-flex me-3 mb-lg-2 align-items-center">
                <input
                  class="me-1"
                  id="how-whiskey"
                  name="drink"
                  type="radio"
                  value="whiskey"
                  v-model="drink"
                />
                <label class="label-radio" for="how-whiskey">Whiskey</label>
              </div>
              <div class="d-flex me-3 mb-lg-2 align-items-center">
                <input
                  class="me-1"
                  id="how-vodka"
                  name="drink"
                  type="radio"
                  value="vodka"
                  v-model="drink"
                />
                <label class="label-radio" for="how-vodka">Vodka</label>
              </div>
              <div class="d-flex me-3 mb-lg-2 align-items-center">
                <input
                  class="me-1"
                  id="how-mezcal"
                  name="drink"
                  type="radio"
                  value="mezcal"
                  v-model="drink"
                />
                <label class="label-radio" for="how-mezcal">Mezcal</label>
              </div>
              <div class="d-flex me-3 mb-lg-2 align-items-center">
                <input
                  class="me-1"
                  id="how-gin"
                  name="drink"
                  type="radio"
                  value="gin"
                  v-model="drink"
                />
                <label class="label-radio" for="how-gin">Gin</label>
              </div>
            </div>
          </div>
          <button class="mt-3 mt-lg-5" @click.prevent="submitFormRSVP">
            ¡Confirmo asistencia!
          </button>
          <div
            class="alert mt-3"
            :class="{
              'alert-success': requestData.status === 'success',
              'alert-warning': requestData.status === 'error' || !formIsValid.status,
            }"
            role="alert"
            v-if="requestData.status || !formIsValid.status"
          >
            {{ requestData.msg || formIsValid.msg }}
          </div>
        </div>
      </form>
      <div class="img-wrapper order-lg-1">
        <img src="img/FOTO RSVP.png" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      fullName: '',
      phone: '',
      email: '',
      accommodation: '',
      drink: 'ron',
      requestData: {
        status: undefined,
        msg: '',
      },
      formIsValid: {
        status: true,
        msg: '',
      },
    };
  },
  methods: {
    setFormIsValid(status, msg) {
      this.formIsValid.status = status;
      this.formIsValid.msg = msg;
    },
    validateForm(fields) {
      // eslint-disable-next-line no-restricted-syntax
      for (const field of fields) {
        if (field.value.toString().trim() === '') {
          this.setFormIsValid(false, 'Los campos del formulario no pueden quedar vacios');
          break;
        }
      }
      if (this.formIsValid.status) {
        this.validatePhone(fields[1].value);
      }
      if (this.formIsValid.status) {
        this.validateEmail(fields[2].value);
      }
    },
    validatePhone(phone) {
      if (phone.toString().length !== 10) {
        this.setFormIsValid(false, 'El teléfono debe constar de diez números');
      }
    },
    validateEmail(email) {
      const re = /\S+@\S+\.\S+/;
      if (!re.test(email)) {
        this.setFormIsValid(false, 'Ingresa un correo válido');
      }
    },
    async submitFormRSVP() {
      // eslint-disable-next-line no-restricted-globals
      this.$store.commit('set_btnSpinner', event.target);
      this.setFormIsValid(true, '');

      const data = {
        fields: [
          {
            name: 'company',
            value: this.fullName,
          },
          {
            name: 'phone',
            value: this.phone,
          },
          {
            name: 'email',
            value: this.email,
          },
          {
            name: 'donde_te_hospedaras_',
            value: this.accommodation,
          },
          {
            name: 'que_tomas',
            value: this.drink,
          },
        ],
        context: {
          pageUri: 'https://valydan.com/#/',
          pageName: 'valydan.com',
        },
      };
      this.validateForm(data.fields);

      if (this.formIsValid.status) {
        try {
          const response = await this.$store.dispatch('submitRSVP', data);
          if (response.inlineMessage) {
            this.requestData.status = 'success';
            this.requestData.msg = 'Formulario enviado. Gracias por confirmar tu asistencia.';
          } else {
            this.requestData.status = 'error';
            this.requestData.msg = 'Error al enviar el formulario';
          }
        } catch (error) {
          this.requestData.status = 'error';
          this.requestData.msg = 'Error al enviar el formulario';
        } finally {
          this.$store.commit('set_btnSpinner');
          this.clearInputs();
        }
      } else {
        this.$store.commit('set_btnSpinner');
      }
    },
    clearInputs() {
      this.fullName = '';
      this.phone = '';
      this.email = '';
      this.accommodation = '';
      this.drink = 'ron';

      setTimeout(() => {
        this.requestData = {
          status: undefined,
          msg: '',
        };
      }, 7000);
    },
  },
};
</script>

<style lang="scss" scoped>
.page-section {
  height: 200vh !important;
  max-height: 200vh;
  background: black;
  @media (min-width: 992px) {
    max-height: 100vh;
  }
}
form {
  // margin: 95px 0;
  width: 100%;
  padding: 0 25px;
  height: 91vh;
  overflow-y: hidden;
  background: white;

  label {
    font-weight: 300;
    font-size: 14px;
    line-height: 29px;
    color: #82837f;
    text-align: left;
  }

  .label-radio {
    font-size: 16px;
  }

  input[type="text"],
  input[type="number"] {
    border: 0px;
    border-bottom: 1px solid #90908d;
    width: 90%;
    margin-bottom: 15px;
    height: 13px;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }

  input[type="radio"] {
    border: 0px;
    width: 20px;
    height: 15px;
    // width: 31px;
    // height: 31px;
  }

  button {
    background: #000000;
    border-radius: 14px;
    width: 85%;
    height: 50px;
    // width: 426px;
    // height: 77px;
    color: white;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 20px;
    font-size: 16px;
    line-height: 29px;
  }

  .input-wrapper {
    display: flex;
    flex-direction: column;
  }

  .radio-wrapper {
    display: flex;
    flex-direction: column;
  }

  @media (orientation: landscape) {
    .input-wrapper {
      flex-direction: row;
    }
    .radio-wrapper {
      flex-direction: row;
    }
    button {
      background: #000000;
      border-radius: 14px;
      width: 70%;
      height: 40px;
      // width: 426px;
      // height: 77px;
      color: white;
      text-transform: uppercase;
      font-weight: bold;
      font-size: 20px;
      font-size: 16px;
      line-height: 29px;
    }
  }

  @media (min-height: 576px) and (min-width: 992px) {
    .radio-wrapper {
      flex-direction: column;
    }
  }

  @media (min-width: 992px) {
    height: 87vh;
    margin: 0 0;
    padding: 20px;

    label {
      font-size: 22px;
    }

    input[type="text"],
    input[type="number"] {
      height: 40px;
    }

    .label-radio {
      font-size: 18px;
    }

    input[type="radio"] {
      width: 31px;
      height: 31px;
    }

    button {
      width: 100%;
      height: 77px;
      font-size: 24px;
      margin-top: 15px !important;
    }
  }
}
.img-wrapper {
  min-width: 50%;
  height: 100vh;
  overflow: hidden;
  background: #000000;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: 50% 0%;
  }
  @media (min-width: 992px) {
    height: 87vh;
    img {
      height: 87vh;
    }
  }
}
</style>
