<template>
  <div class="page-section">
    <div class="days-to-go d-flex flex-column justify-content-center">
      <h1 class="days-to-go__number">{{ daysToGo }}</h1>
      <h2 class="days-to-go__text">DAYS TO GO!</h2>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      daysToGo: 0,
    };
  },
  created() {
    let today = new Date();
    const offset = today.getTimezoneOffset();
    today = new Date(today.getTime() - offset * 60 * 1000);

    const startDate = today.toISOString().split('T')[0];
    const endDate = '2022-03-19';

    const diffInMs = new Date(endDate) - new Date(startDate);
    const diffInDays = diffInMs / (1000 * 60 * 60 * 24);
    this.daysToGo = diffInDays;
  },
};
</script>

<style lang="scss" scoped>
.page-section {
  height: auto !important;
  min-height: auto !important;
}

.days-to-go {
  background: #000000;
  // background: #424242;
  height: auto !important;
  &__number {
    font-size: 60px;
    margin-bottom: 0;
    color: #ededeb;
  }
  &__text {
    font-size: 33px;
    color: #ededeb;
  }

  @media (min-width: 992px) {
    background: #000000;
    // height: 326px;
    &__number {
      font-size: 120px;
    }
    &__text {
      font-size: 66px;
      line-height: 80px;
    }
  }
}
</style>
