<template>
  <div class="page-section" id="transporte">
    <div class="page-header d-flex flex-column align-items-center justify-content-center">
      <h1 class="page-header__title">Transporte</h1>
    </div>
    <div class="d-flex flex-column flex-sm-row justify-content-center align-items-center wrapper">
      <img src="img/van.png" alt="">
      <p>A partir de las 11pm, regreso a los 3 hoteles sede con frecuencia cada ~hora</p>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    openLink() {
      window.open('https://goo.gl/maps/tQaWASUrSm79hJER6', '_blank');
    },
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  min-height: 90vh;
}
img {
  width: 100%;
}
p {
  font-size: 20px;
  margin: 5px;
}
@media (min-width: 576px) {
  img {
    width: 50%;
  }
}
@media (min-width: 992px) {
  p {
  font-size: 26px;
}
}
</style>
