<template>
  <div class="page-section" id="hospedaje">
    <div class="page-header d-flex align-items-center justify-content-center">
      <h1 class="page-header__title">Hospedaje</h1>
    </div>
    <div class="cards-mobile">
      <div id="carouselAccomodationControls" class="carousel slide" data-bs-ride="carousel">
        <div class="carousel-inner">
          <div class="carousel-item active">
            <div class="accomodation-card cards-mobile">
              <div>
                <img :src="accomodations[0].picture" alt="" class="accomodation-card__picture" />
                <a
                  class="accomodation-card__name mt-5"
                  :href="accomodations[0].url"
                  target="_blank"
                  >{{ accomodations[0].name }}</a
                >
              </div>
              <div class="mt-lg-5 accomodation-card__info d-flex flex-column align-items-start">
                <!-- <h4 v-if="accomodation.code">
                Código: <span>{{ accomodation.code }}</span>
              </h4> -->
                <h4>
                  Dirección: <span>{{ accomodations[0].direction }}</span>
                </h4>
                <h4>
                  Distancia hacia Agua Viva: <span>{{ accomodations[0].distance }}</span>
                </h4>
                <h4>
                  Tiempo: <span>{{ accomodations[0].time }}</span>
                </h4>
              </div>
            </div>
          </div>
          <div class="carousel-item">
            <div class="accomodation-card cards-mobile">
              <div>
                <img :src="accomodations[1].picture" alt="" class="accomodation-card__picture" />
                <a
                  class="accomodation-card__name mt-5"
                  :href="accomodations[1].url"
                  target="_blank"
                  >{{ accomodations[1].name }}</a
                >
              </div>
              <div class="mt-lg-5 accomodation-card__info d-flex flex-column align-items-start">
                <h4>
                  Código: <span>{{ accomodations[1].code }}</span>
                </h4>
                <h4>
                  Dirección: <span>{{ accomodations[1].direction }}</span>
                </h4>
                <h4>
                  Distancia hacia Agua Viva: <span>{{ accomodations[1].distance }}</span>
                </h4>
                <h4>
                  Tiempo: <span>{{ accomodations[1].time }}</span>
                </h4>
              </div>
            </div>
          </div>
          <div class="carousel-item">
            <div class="accomodation-card cards-mobile">
              <div>
                <img :src="accomodations[2].picture" alt="" class="accomodation-card__picture" />
                <a
                  class="accomodation-card__name mt-5"
                  :href="accomodations[2].url"
                  target="_blank"
                  >{{ accomodations[2].name }}</a
                >
              </div>
              <div class="mt-lg-5 accomodation-card__info d-flex flex-column align-items-start">
                <h4>
                  Código: <span>{{ accomodations[2].code }}</span>
                </h4>
                <h4>
                  Dirección: <span>{{ accomodations[2].direction }}</span>
                </h4>
                <h4>
                  Distancia hacia Agua Viva: <span>{{ accomodations[2].distance }}</span>
                </h4>
                <h4>
                  Tiempo: <span>{{ accomodations[2].time }}</span>
                </h4>
              </div>
            </div>
          </div>
        </div>
        <button
          class="carousel-control-prev"
          type="button"
          data-bs-target="#carouselAccomodationControls"
          data-bs-slide="prev"
        >
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Previous</span>
        </button>
        <button
          class="carousel-control-next"
          type="button"
          data-bs-target="#carouselAccomodationControls"
          data-bs-slide="next"
        >
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Next</span>
        </button>
      </div>
      <div class="d-flex flex-column align-items-center justify-content-center airbnb-section">
        <h4 class="airbnb-text">o si lo prefieres selecciona tu</h4>
        <a
          href="https://www.airbnb.mx/s/Xochitepec--Morelos/homes?adults=2&place_id=ChIJE4w50rrZzYURFk_w25UNFWI&checkin=2022-03-18&checkout=2022-03-20&tab_id=home_tab&refinement_paths%5B%5D=%2Fhomes&query=Xochitepec%2C%20Morelos&flexible_trip_lengths%5B%5D=weekend_trip&date_picker_type=calendar&ne_lat=18.78422115971304&ne_lng=-99.20211001127723&sw_lat=18.756387595198497&sw_lng=-99.24601240843299&zoom=15&search_by_map=true&search_type=user_map_move"
          target="_blank"
        >
          <img class="mt-2 airbnb-pic" src="img/airbnb.png" alt="" />
        </a>
      </div>
    </div>
    <div class="cards-desktop">
      <div
        class="accomodations d-flex flex-column flex-lg-row justify-content-between align-items-center"
      >
        <div class="accomodation" v-for="accomodation of accomodations" :key="accomodation.code">
          <div>
            <img :src="accomodation.picture" alt="" class="accomodation__picture" />
            <a class="accomodation__name mt-5" :href="accomodation.url" target="_blank">{{
              accomodation.name
            }}</a>
          </div>
          <div class="mt-2 mt-lg-5 accomodation__info d-flex flex-column align-items-start">
            <h4 v-if="accomodation.code">
              Código: <span>{{ accomodation.code }}</span>
            </h4>
            <h4>
              Dirección: <span>{{ accomodation.direction }}</span>
            </h4>
            <h4>
              Distancia hacia Agua Viva: <span>{{ accomodation.distance }}</span>
            </h4>
            <h4>
              Tiempo: <span>{{ accomodation.time }}</span>
            </h4>
          </div>
        </div>
      </div>
      <div class="d-flex flex-column align-items-center justify-content-center airbnb-section">
        <h4 class="airbnb-text">o si lo prefieres selecciona tu</h4>
        <a
          href="https://www.airbnb.mx/s/Xochitepec--Morelos/homes?adults=2&place_id=ChIJE4w50rrZzYURFk_w25UNFWI&checkin=2022-03-18&checkout=2022-03-20&tab_id=home_tab&refinement_paths%5B%5D=%2Fhomes&query=Xochitepec%2C%20Morelos&flexible_trip_lengths%5B%5D=weekend_trip&date_picker_type=calendar&ne_lat=18.78422115971304&ne_lng=-99.20211001127723&sw_lat=18.756387595198497&sw_lng=-99.24601240843299&zoom=15&search_by_map=true&search_type=user_map_move"
          target="_blank"
        >
          <img class="mt-2 airbnb-pic" src="img/airbnb.png" alt="" />
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      accomodations: [
        {
          picture: 'img/FOTO SALON AGUA VIVA-1.png',
          name: 'Hotel Fiesta Americana San Antonio El Puente',
          url: 'https://www.fiestamericana.com/fiesta-americana-hacienda-san-antonio-el-puente-cuernavaca',
          code: '',
          direction: 'Reforma No.2 Fracc. Real del Puente, 62790 Mor.',
          distance: '5.3 km',
          time: '10 min.',
        },
        {
          picture: 'img/FOTO SALON AGUA VIVA-2.png',
          name: 'MISIÓN GRAND CUERNAVACA',
          url: 'https://www.hotelesmision.com.mx/destinos/morelos/hotel-mision-grand-cuernavaca',
          code: 'BODA8',
          direction: 'C. Galeana 29, Acapatzingo, 62440 Cuernavaca, Mor.',
          distance: '19.5 km',
          time: '25 min.',
        },
        {
          picture: 'img/FOTO SALON AGUA VIVA-3.png',
          name: 'HOTEL HOLIDAY INN EXPRESS & SUITES CUERNAVACA',
          url: 'https://www.ihg.com/holidayinnexpress/hotels/us/en/cuernavaca/cvjjh/hoteldetail?cm_mmc=GoogleMaps-_-EX-_-MX-_-CVJJH',
          code: 'BVD',
          direction: 'José María Morelos 133, Las Palmas, 62070 Cuernavaca, Mor.',
          distance: '11.5 km',
          time: '25 min.',
        },
      ],
    };
  },
};
</script>

<style lang="scss">
.page-section#hospedaje {
  min-height: 91vh !important;

  @media (min-width: 992px) {
    min-height: 91vh !important;
  }
}

.cards-mobile {
  display: block;
  height: 91vh !important;
}
.cards-desktop {
  display: none;
}

// @media (min-width: 992px) {
//   .cards-mobile {
//     display: none;
//   }
//   .cards-desktop {
//     display: block;
//   }
// }

@media (orientation: landscape) {
  .cards-mobile {
    display: block;
  }
  .cards-desktop {
    display: none;
  }
}
@media (min-height: 576px) and (min-width: 576px) {
  .cards-mobile {
    display: block;
  }
  .cards-desktop {
    display: none;
  }
}

@media (min-height: 768px) and (min-width: 992px) {
  .cards-mobile {
    display: none;
  }
  .cards-desktop {
    display: block;
  }
}

.accomodations {
  // margin: 0 67px;
  .accomodation {
    color: #90908d;
    margin: 15px 31px;
    height: 70vh;
    width: 30vw;

    &__picture {
      width: 100%;
    }
    &__name {
      font-size: 18px;
      text-decoration: none;
      color: #90908d !important;
      text-transform: uppercase;
      font-weight: 700;
      text-align: left;
      padding: 10px;
    }
    &__info {
      padding: 0 30px;
      h4 {
        font-weight: 700;
        font-size: 18px;
        margin: 0;
        text-align: left;

        span {
          font-weight: 500;
        }
      }
    }
  }
}
// .img-wrapper {
//   overflow: hidden;
//   img {
//     width: 100vw !important;
//     height: 40vh !important;
//     overflow: hidden;
//     object-fit: cover;
//     border-radius: 50px;
//   }
// }

.accomodation-card {
  color: #90908d;
  // margin: 15px 31px;
  width: 100%;
  min-height: 80vh;
  max-height: 80vh;
  padding: 0 25px;

  &__picture {
    width: 100%;
    height: 40vh;
    margin: 10px auto;
    border-radius: 50px;
    overflow: hidden;
    object-fit: cover;
  }
  &__name {
    font-size: 18px;
    text-decoration: none;
    color: #90908d !important;
    text-transform: uppercase;
    font-weight: 700;
    text-align: left;
    padding: 10px;
  }
  &__info {
    margin-top: 50px !important;
    h4 {
      font-weight: 700;
      font-size: 18px;
      margin: 0;
      text-align: left;

      span {
        font-weight: 500;
      }
    }
  }
  @media (orientation: landscape) {
    width: 75%;
    margin: auto;
    &__picture {
      width: 100%;
      height: 40vh;
      margin: 10px auto;
    }
    &__name {
      font-size: 14px;
      padding: 0;
    }
    &__info {
      margin-top: 0 !important;
      h4 {
        font-size: 14px;
        margin: 0;
      }
    }
  }

  @media (min-height: 576px) and (min-width: 576px) {
  }

  @media (min-height: 576px) and (min-width: 992px) {
    width: 75%;
    margin: auto;
    &__picture {
      width: 100%;
      height: 40vh;
      margin: 10px auto;
    }
    &__name {
      font-size: 18px;
      padding: 0;
    }
    &__info {
      margin-top: 0 !important;
      h4 {
        font-size: 18px;
        margin: 0;
      }
    }
  }
}
.airbnb-section {
  .airbnb-text {
    font-weight: bold;
    margin-top: 15px;
    font-size: 20px;
    line-height: 27px;
    display: none;
  }
  @media (orientation: landscape) {
    .airbnb-pic {
      height: 6vh;
    }
  }
  @media (min-height: 576px) and (min-width: 992px) {
    .airbnb-pic {
      height: 5vh;
    }
  }
  @media (min-height: 768px) and (min-width: 992px) {
    .airbnb-text {
      margin-bottom: 0px;
      display: block;
    }
  }
}

button.carousel__prev {
  background-color: red !important;
}

.agile__nav-button {
  position: relative;
  border-radius: 15px;
  top: -50vh;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  filter: invert(100%);
  margin-top: 122px !important;
  // background: #858585;
  // border: 5px solid #858585;
  // border-radius: 50%;
}
</style>
