<template>
  <div class="page-section d-flex justify-content-center align-items-center" id="queremos-ver-tus-fotos">
    <div class="social-footer">
      <h1 class="social-footer__title">
        Queremos <br />
        ver tus fotos
      </h1>
      <img class="social-footer__icon" src="img/icons/instagram-icon.png" alt="" />
      <h2 class="social-footer__hashtag">#valydan</h2>
    </div>
    <div class="page-bottom"></div>
  </div>
</template>

<style lang="scss" scoped>
.page-section {
  background: #1f1f1f;
  height: 100vh;
}
.social-footer {
  background: #1f1f1f;
  &__title {
    font-weight: 600;
    font-size: 28px;
    line-height: 36px;
    text-transform: uppercase;
    color: white;
  }
  &__icon {
    width: 82px;
    margin: 26px;
  }
  &__hashtag {
    font-size: 27px;
    font-style: italic;
    color: #90908d;
  }

  @media (min-width: 992px) {
    &__title {
      font-size: 54px;
      line-height: 66px;
    }
    &__icon {
      width: 164px;
      margin: 52px;
    }
    &__hashtag {
      font-size: 54px;
    }
  }
}
.page-bottom {
  background: black;

  @media (min-width: 992px) {
    height: 115px;
  }
}
</style>
